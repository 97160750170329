<template>
	<div class="Perfil">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<h2 class="indigo--text text--darken-4">Olá, {{data.session.nome_usuario}}!</h2>
				<p>Aqui encontram-se alguns dados sobre seu cadastro.</p>
			</v-flex>
		</v-layout>
		<v-layout row wrap justify-space-between class="mx-5 mt-15">
			<v-flex xs3 lg2 class="text-center">
				<v-avatar width="100%" height="auto" color="grey lighten-4">
					<img src="../resources/img/default.png" :alt="data.session.nome_usuario">
				</v-avatar>
			</v-flex>
			<v-flex xs8 lg9>
				<v-simple-table>
					<template v-slot:default>
						<tbody>
							<tr>
								<td width="200">Nome</td>
								<td>{{data.session.nome_usuario}}</td>
							</tr>
							<tr>
								<td>Cargo</td>
								<td>{{data.session.cargo_usuario}}</td>
							</tr>
							<tr>
								<td>CPF</td>
								<td>{{data.session.cpf}}</td>
							</tr>
							<tr>
								<td>Login</td>
								<td>{{data.session.login}}</td>
							</tr>
							<tr>
								<td>Senha</td>
								<td>
									<v-dialog scrollable v-model="showDialogNovaSenha" max-width="600">
										<template v-slot:activator="{ on, attrs }">
											<v-btn depressed dark color="indigo darken-4" v-bind="attrs" v-on="on">
												<v-icon left>security</v-icon>
												<span>Alterar senha</span>
											</v-btn>
										</template>
										<v-card height="fit-content">
											<v-system-bar color="blue darken-4"></v-system-bar>

											<v-card-title class="headline grey--text text--darken-2">Alteração de senha</v-card-title>

											<v-card-text>
												<v-form class="my-3" ref="formNovaSenha">
													<p>Insira a sua senha atual e a nova senha, juntamente da confirmação.</p>
													<v-text-field label="Senha Altual" prepend-icon="security" v-model="atualpass" :type="'password'" :rules="inputValidatePass"></v-text-field>
													<v-text-field label="Nova Senha" prepend-icon="fingerprint" v-model="newpass" :type="'password'" :rules="inputValidateConfirmPass"></v-text-field>
													<v-text-field label="Confirmação da nova Senha" prepend-icon="done_all" v-model="confirmpass" @blur="validateForm" :type="'password'" :rules="inputValidateConfirmPass"></v-text-field>
												</v-form>
											</v-card-text>

											<v-card-actions class="my-5">
												<v-btn depressed dark color="blue darken-4" @click="showDialogNovaSenha = !showDialogNovaSenha">
													<v-icon left>close</v-icon>
													<span>Cancelar</span>
												</v-btn>
												<v-btn depressed dark color="indigo darken-4" @click="registraNovaSenha" :loading="loadingStatus">
													<v-icon left>done_all</v-icon>
													<span>Alterar</span>
												</v-btn>
											</v-card-actions>

											<v-system-bar color="blue-grey darken-1"></v-system-bar>
										</v-card>
									</v-dialog>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-flex>
		</v-layout>
		<v-snackbar v-model="snackbarShower" :timeout="2000" top :color="snackbarColor">
			<v-icon>verified_user</v-icon> {{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
export default {
	data: function () {
	    return {
	    	data: {
				session: {
					nome_usuario: localStorage.getItem('nome_usuario'),
					cargo_usuario: localStorage.getItem('cargo_usuario'),
					cpf: localStorage.getItem('cpf'),
					login: localStorage.getItem('login')
				}
			},
	    	atualpass: '',
	    	newpass: '',
	    	confirmpass: '',
	    	showDialogNovaSenha: false,
	    	loadingStatus: false,
	    	inputValidatePass: [
				v => v.length != '' || 'Digite a senha'
			],
			inputValidateConfirmPass: [
				v => v.length != '' || 'Digite a senha',
				v => this.newpass == this.confirmpass || 'Senha Nova e Confirmação devem coincidir'
			],
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false
	 	}
	},
	methods:{
		validateForm() {
      		this.$refs.formNovaSenha.validate()
      	},
		registraNovaSenha() {
      		if (this.$refs.formNovaSenha.validate()) {
      			this.loadingStatus = true;

      			var thisEl = this;
      			setTimeout(function(){
					thisEl.$http.post(//requisição ajax
                        'login/atualizar_senha',
                        {
                        	token: localStorage.getItem('token'),
                            atualpass: thisEl.atualpass,
                            newpass: thisEl.newpass,
                            confirmpass: thisEl.confirmpass
                        },
                        { emulateJSON: true }
                    ).then(
                        function(r){//sucesso
                            if (r.body.token == false) {
                            	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
			      				
			      				setTimeout(function(){
                            		thisEl.$router.push('/logout');
                            	}, 2000);
                            } else {
                            	if (r.body.success) {
	                            	thisEl.snackbarColor = 'green';
				      				thisEl.snackbarText = r.body.message;
	                            	thisEl.snackbarShower = true;

	                            	setTimeout(function(){
	                            		thisEl.$router.go();//refresh atual route
	                            	}, 2000);
	                            } else {
	                            	thisEl.loadingStatus = false;
	                            	thisEl.snackbarColor = 'red';
				      				thisEl.snackbarText = r.body.message;
				      				thisEl.snackbarShower = true;
	                            }
                            }
    					},
                        function(error){//erro na requisição, por exemplo 404
                            thisEl.loadingStatus = false;
                        	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = 'Houve um erro com a requisição.';
		      				thisEl.snackbarShower = true;
                        }
                    ).finally(function(){//executa este método após toda execução do then
                        //do something
                    });
                }, 1000);
      		}
      	}
	}
};
</script>